
import {defineComponent, onBeforeUnmount, onMounted, reactive, ref} from "vue";
import {makeRequest, METHODS} from "@/utils/apiUtils";
import UIModal from "@/components/UI/UIModal.vue";
import UIButton from "@/components/UI/UIButton.vue";
import { showToast } from '@/hooks/useToast';
import {SocketIO} from "@/utils/socketUtils";
type Month = {
    month: string; exists: boolean; db: boolean
}
export default defineComponent({
  name: 'MiiStatuses',
    components: {'ui-button': UIButton, 'ui-modal': UIModal},
  setup() {
    const selectedMonth = ref<Month | null>(null);
    const modalState = ref(false);
    const months = ref<Month[]>([]);
    const updating = reactive({ actual: 0, total: 0 });
    const requestMiiCheck = async () => {
        const request = await makeRequest<{ monthlyStatus: Month[] }>(METHODS.GET, 'file/miis-check', true);
        if (request.ok && request.payload) {
            months.value = request.payload.monthlyStatus;
        }
    };
    const showMonthModal = (m: Month) => {
        selectedMonth.value = m;
        modalState.value = true;
    };
    const loadingExtraData = ref(false);
    const requestLoadExtraData = async () => {
        try {
            loadingExtraData.value = true;
            if (!selectedMonth.value) return;
            const request = await makeRequest<{
                monthlyStatus: Month[]
            }>(METHODS.POST, 'guia-extra-data', true, {periodo: selectedMonth.value.month});
            if (request.ok && request.payload) {
                months.value = request.payload.monthlyStatus;
                showToast('SUCCESS', 'Datos anexados', true);
            }
        } catch{
            showToast('ERROR', 'Error anexando datos', true);
        } finally {
            await requestMiiCheck();
            modalState.value = false;
            loadingExtraData.value = false;
            updating.actual = 0;
            updating.total = 0;
        }
    };
    const socket = SocketIO.getInstance();
    const onSavedExtraData = (payload: any) => {
      updating.actual = payload.actual as number;
      updating.total = payload.total as number;
    };
    onMounted(async () => {
      await requestMiiCheck();
      socket.joinRoom(`mii-extra-data`, [{ evt: 'saving', cb: onSavedExtraData  }]);
    });
    onBeforeUnmount(async () => {
      await socket.leaveRoom(`mii-extra-data`);
    });
    return {
      months,
        modalState,
        showMonthModal,
        selectedMonth,
        loadingExtraData,
        requestLoadExtraData,
      updating
      };
  }
});
