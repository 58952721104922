
import {defineComponent, onBeforeUnmount, onMounted, reactive, ref} from 'vue';
import UIButton from '@/components/UI/UIButton.vue';
import UITable from '@/components/UI/UITable.vue';
import { makeRequest, METHODS, requestFile, sendFile } from '@/utils/apiUtils';
import moment from 'moment';
import { showToast } from '@/hooks/useToast';
import UITextSelect from '@/components/UI/UITextSelect.vue';
import MiiStatuses from "@/components/common/MiiStatuses.vue";
import {SocketIO} from "@/utils/socketUtils";

interface IMiiResume {
  createdAt: number;
  updatedAt: number;
  periodo: string;
  _id: string;
}

export default defineComponent({
  name: 'MiiImporter',
  components: {
		MiiStatuses,
    'ui-button': UIButton,
    'ui-table': UITable,
    'ui-text-select': UITextSelect
  },
  setup() {
    const headers = [
      { label: 'Creado el', key: 'created', format: '' },
      { label: 'Actualizado el', key: 'updated', format: '' },
      { label: 'Periodo', key: 'periodo', format: '' }
    ];
    const actions = [
      {
        label: 'DESCARGAR',
        class: 'bg-yellow-600 text-white',
        cb: (i: number) => getMii(i)
      }
    ];
    const items = ref<
      {
        uid: string;
        data: { created: string; updated: string; periodo: string };
      }[]
    >([]);
    const updating = reactive({ actual: 0, total: 0 });

    const getMii = async (i: number) => {
      const mii = items.value[i];
      const request = await requestFile(`file/mii/${mii.data.periodo}`, `mii-${mii.data.periodo}`);
      if (request.ok) {
        console.log({ request });
      } else {
        showToast('ERROR', request.errors + '');
      }
    };

    const isLoading = ref(false);

    const fileRef = ref<any | null>(null);
    const file = ref<any>(null);

    const pickFile = () => {
      isLoading.value = true;
      fileRef.value?.click();
    };
    const charge = () => {
      const checkOnCancel = () => {
        const fileElem = document.getElementById('fileinput') as HTMLInputElement;
        if (fileElem && fileElem.value.length == 0) isLoading.value = false;
      };
      document.body.onfocus = function () {
        setTimeout(checkOnCancel, 100);
      };
    };
    const onFilePicked = (evt: any) => {
      file.value = evt.target.files[0];
      isLoading.value = false;
    };

    //
    const months = [
      { id: '01', label: 'Enero' },
      { id: '02', label: 'Febrero' },
      { id: '03', label: 'Marzo' },
      { id: '04', label: 'Abril' },
      { id: '05', label: 'Mayo' },
      { id: '06', label: 'Junio' },
      { id: '07', label: 'Julio' },
      { id: '08', label: 'Agosto' },
      { id: '09', label: 'Septiembre' },
      { id: '10', label: 'Octubre' },
      { id: '11', label: 'Noviembre' },
      { id: '12', label: 'Diciembre' }
    ];
    const years: { id: string; label: string }[] = [];
    const modes = [
      { id: 'write', label: 'Crear/Reemplazar' },
      { id: 'update', label: 'Actualizar/Agregar' }
    ];
    const month = ref('');
    const year = ref('');
    const mode = ref('');
    //

    const uploadFile = async () => {
      try {
        if (!month.value || !year.value || !mode.value) return;
        isLoading.value = true;
        updating.actual = 0;
        const url = `/file/upload-mii/${month.value}-${year.value}/${mode.value}`;
        const req = await sendFile(url, file.value);
        if (req.ok) {
          file.value = null;
          showToast('SUCCESS', 'Mii subido exitosamente');
        } else {
          showToast('ERROR', 'Error subiendo mii');
        }
      } catch (error) {
        console.log(error);
        showToast('ERROR', 'Error subiendo mii');
      } finally {
        isLoading.value = false;
      }
    };

    onMounted(async () => {
      // socket.joinRoom(`mii-extra-data`, [{ evt: 'saving', cb: onSavedExtraData  }]);
      const request = await makeRequest<IMiiResume[]>(METHODS.GET, 'file/miis', true);
      if (request.ok && request.payload && request.payload.length) {
        items.value = request.payload.map((el) => ({
          uid: el._id,
          data: {
            created: moment(el.createdAt).format('DD-MM-YYYY HH:mm'),
            updated: el.updatedAt ? moment(el.updatedAt).format('DD-MM-YYYY HH:mm') : '---',
            periodo: el.periodo
          }
        }));
      }
      const actualYear = moment().year();
      [0, -1, -2, -3, -4, -5].forEach((i) => {
        years.push({
          id: (actualYear + i).toString(),
          label: (actualYear + i).toString()
        });
      });
    });

    return {
      headers,
      actions,
      items,
      fileRef,
      pickFile,
      onFilePicked,
      file,
      uploadFile,
      isLoading,
      charge,
      months,
      years,
      modes,
      month,
      year, 
      mode,
      updating
    };
  }
});
