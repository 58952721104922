
import {
  computed,
  defineComponent, nextTick,
  onMounted,
  PropType,
  ref,
  watch
} from 'vue';
import { SelectItem } from '@/models/SelectItem';

export default defineComponent({
  name: 'TextSelect',
  props: {
    label: {
      type: String,
      default: 'Seleccione...'
    },
    items: {
      type: Array as PropType<SelectItem[]>,
      default: () => [
        { label: 'Item 1', id: 1 },
        { label: 'Item 2', id: 2 }
      ]
    },
    required: {
      type: Boolean,
      default: false
    },
    default: {
      type: String
    },
    disabled: {
      type: Boolean,
      default: false
    },
    tabIndex: {
      type: Number,
      default: 0
    },
    openText: {
      type: Boolean,
      default: false
    },
    maxHeight: {
      type: String,
      default: '149'
    },
    dismissible: {
      type: Boolean,
      default: true
    }
  },
  emits: ['selected', 'unselect', 'change'],
  setup(props, { emit }) {
    const value = ref<SelectItem | null>(null);
    const preSelected = () => {
      const item = props.items.find((el) =>
        el.id === props.default
      );
      item && (value.value = item);
      item && (filter.value = item.label);
      value.value && emit('selected', value.value.id);
      setTimeout(() => showOptionsHandler(false), 100);
    };
    onMounted(() => {
      props.default && preSelected();
    });
    watch(() => props.default, () => preSelected());
    const showOptions = ref(false);
    const showOptionsHandler = (show: boolean) => {
      if (props.disabled) {
        showOptions.value = false;
        return;
      }
      showOptions.value = show;
      dirty.value = true;
    };
    const onSelect = async (val: SelectItem) => {
      document.removeEventListener('click', listener);
      console.log('SELECT');
      value.value = val;
      filter.value = val.label;
      emit('selected', val.id);
      await nextTick();
      showOptions.value = false;
    };
    const empty = ref(false);
    const ring = computed(() => {
      if (empty.value && dirty.value && props.required && !value.value) return 'ring-red-500';
      return showOptions.value ? 'ring-green-800' : 'ring-green-200';
    });
    const validationFn = () => {
      if (props.required && !value.value) empty.value = true;
      else empty.value = false;
      dirty.value = true;
      if(empty.value) console.log(props.label);
      return !empty.value;
    };
    watch(
      () => showOptions.value,
      (actual, prev) => {
        if (prev === true && actual === false) validationFn();
      }
    );
    const labelColor = computed(() => {
      if (showOptions.value && !props.disabled) return 'bg-green-800';
      return value.value ? 'bg-green-500' : 'bg-red-500';
    });

    const dirty = ref(false);
    const showErrorMessage = computed(() => {
      if (dirty.value && !showOptions.value && props.required && !value.value ) return true;
      return false;
    });

    const unselect = async () => {
      empty.value = true;
      dirty.value = true;
      value.value = null;
      filter.value = '';
      // showOptions.value = true;
      emit('selected', '');
      emit('unselect');
    };

    const filter = ref('');
    const filteredItems = computed(() => {
      if (!filter.value) return props.items;
      return props.items.filter(el => el.label.toUpperCase().includes(filter.value.toUpperCase()));
    });
    watch(() => filter.value, (actual, prev) => {
      if (actual && prev) showOptions.value = true;
    });
    const setValue = () => {
      // const item = props.items.find(el => el.label === filter.value);
      // if (item) value.value = item;
      // else if (props.openText) value.value = { label: filter.value, id: 0 };
      // else {
      //   filter.value = '';
      //   value.value = null;
      // }
      filter.value = '';
      showOptions.value = false;
    };

    const cleanValue = () => {
      value.value = null;
      filter.value = '';
      showOptions.value = false;
      dirty.value = false;
    };

    const onChange = (evt: any) => {
      console.log('CHANGED');
    };

    const onFocus = () => {
      console.log('FOCUS !');
      showOptionsHandler(true);
    };

    const activeElement = ref('');
    const setActiveElementByIndex = (i: number) => {
      const elem = document.getElementById(props.items[i].id+'');
      if (elem) {
        activeElement.value = props.items[i].id+'';
        activeElement.value = props.items[i].id+'';
        elem.focus();
        const container = document.getElementById('options-container');
        container && container.addEventListener('keyup', (e) => e.preventDefault());
        container && container.addEventListener('keydown', (e) => e.preventDefault());
      }
    };
    const getNextActiveElement = () => {
      const actualIndex = props.items.findIndex(el => el.id === activeElement.value);
      if (actualIndex !== -1) {
        const next = actualIndex+1;
        setActiveElementByIndex(next < props.items.length ? next : 0);
      }
    };
    const getPrevActiveElement = () => {
      const actualIndex = props.items.findIndex(el => el.id === activeElement.value);
      if (actualIndex !== -1) {
        setActiveElementByIndex(actualIndex === 0 ? props.items?.length-1 : actualIndex -1);
      }
    };

    const listener = (el: any) => {
      const t = el.target;
      const innerClick = t.classList.contains('modal');
      const selfClick = t.classList.contains(cssTag.value);
      if (!innerClick || !selfClick) {
        showOptions.value = false;
      }

      // const p = [...el.path];
      // const clicked =  p[0];
      // const innerClick = clicked.classList.contains('modal');
      // const selfClick = clicked.classList.contains(cssTag.value);
      // if (!innerClick || !selfClick) {
      //   showOptions.value = false;
      // }
    };

    watch(() => showOptions.value, async (val) => {

      if (val) {
        await nextTick();
        setActiveElementByIndex(0);
        document.addEventListener('click', listener);
      } else {
        activeElement.value = '';
        document.removeEventListener('click', listener);
      }
    });

    const isPressingUp = () => {
      getPrevActiveElement();
    };

    const isPressingDown = () => {
      getNextActiveElement();
    };

    const cssTag = ref('');
    onMounted(() => {
      const tag = Math.random().toString().split('.')[1];
      cssTag.value = 'drop-'+tag;
    });

    return {
      value,
      showOptions,
      onSelect,
      ring,
      validationFn,
      labelColor,
      showOptionsHandler,
      dirty,
      showErrorMessage,
      unselect,
      filter,
      filteredItems,
      setValue,
      cleanValue,
      onChange,
      onFocus,
      isPressingDown,
      isPressingUp,
      cssTag
    };
  }
});
